<template>
  <AccountLayout>
    <a-card hoverable class="account-right-container">
      <div class="login-container">
        <a-tabs class="login-container-tabs" default-active-key="1" :animated="false">
          <a-tab-pane key="1" tab="账号登录">
            <up-form @submit="doVerifyUP" :formLoading="formLoading"></up-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="验证码登录"
            ><phone-captcha-form
              ref="phoneCaptchaForm"
              v-if="mode === 1"
              @sendCaptcha="doVerifyPhoneCaptcha"
              @submit="loginPhoneWithCaptcha"
              :formLoading="formLoading"
            ></phone-captcha-form>
            <email-captcha-form
              ref="emailCaptchaForm"
              v-if="mode === 2"
              @sendCaptcha="doVerifyEmailCaptcha"
              @submit="loginEmailWithCaptcha"
              :formLoading="formLoading"
            ></email-captcha-form>
            <a-button
              type="link"
              :block="true"
              @click="switchMode"
              v-if="mode === 1"
              >使用邮箱验证码登录</a-button
            >
            <a-button
              type="link"
              :block="true"
              @click="switchMode"
              v-if="mode === 2"
              >使用手机验证码登录</a-button
            >
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-card>
    <template slot="outside">
      <Verify
        @success="loginWithPwd"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="upVerify"
      ></Verify>
      <Verify
        @success="loginPhoneSendCaptcha"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="phoneCaptchaVerify"
      ></Verify>
      <Verify
        @success="loginEmailSendCaptcha"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="emailCaptchaVerify"
      ></Verify>
    </template>
  </AccountLayout>
</template>



<script>
import Verify from "@/components/verifition";
import UpForm from "./components/up-form";
import PhoneCaptchaForm from "./components/phone-captcha-form";
import EmailCaptchaForm from "./components/email-captcha-form";
import { loginAPI } from "@/api/login";
import { deepCopy } from "@/libs/utils";
import { LoginMixin } from "@/views/mixins/login";
import md5 from "md5";
export default {
  name: "login",
  props: {
    msg: String,
  },
  mixins: [LoginMixin],
  data() {
    return {
      formFields: {},
      formLoading: false,
      mode: 1,
    };
  },
  components: {
    Verify,
    UpForm,
    PhoneCaptchaForm,
    EmailCaptchaForm,
  },
  mounted() {},
  methods: {
    doVerifyUP(data) {
      this.$refs.upVerify.show();
      this.formFields = data;
    },
    doVerifyPhoneCaptcha(data) {
      this.$refs.phoneCaptchaVerify.show();
      this.formFields = data;
    },
    doVerifyEmailCaptcha(data) {
      this.$refs.emailCaptchaVerify.show();
      this.formFields = data;
    },
    verifyErrorHandler(res) {
      this.$message.error(res.repMsg);
    },
    analyzerLoginRes(res) {
      if (res.status) {
        this.loginSuccessOption(res);
      } else {
        if (res.code === -1000) {
          this.$router.push({
            name: "multi-company",
            params: res.data,
            query: this.$route.query,
          });
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    loginWithPwd(verifyParams) {
      this.formLoading = true;
      let request = deepCopy(this.formFields);
      request.authCaptcha = verifyParams.captchaVerification;
      request.password = md5(this.formFields.password);
      loginAPI
        .loginWithPwd(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    loginPhoneSendCaptcha(verifyParams) {
      this.$refs.phoneCaptchaForm.doRegisterSendCaptchStatus();
      this.formFields.authCaptcha = verifyParams.captchaVerification;
      loginAPI
        .loginPhoneSendCaptcha(this.formFields)
        .then((res) => {
          if (!res.status) {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          if (!res.status) {
            this.$message.error(res.msg);
          }
        });
    },
    loginPhoneWithCaptcha(data) {
      this.formLoading = true;
      let request = deepCopy(data);
      request.authCaptcha = this.formFields.authCaptcha;
      loginAPI
        .loginPhoneWithCaptcha(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    loginEmailSendCaptcha(verifyParams) {
      this.$refs.emailCaptchaForm.doRegisterSendCaptchStatus();
      this.formFields.authCaptcha = verifyParams.captchaVerification;
      loginAPI
        .loginEmailSendCaptcha(this.formFields)
        .then((res) => {
          if (!res.status) {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          if (!res.status) {
            this.$message.error(res.msg);
          }
        });
    },
    loginEmailWithCaptcha(data) {
      this.formLoading = true;
      let request = deepCopy(data);
      request.authCaptcha = this.formFields.authCaptcha;
      loginAPI
        .loginEmailWithCaptcha(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    switchLoginMode() {
      this.loginMode = this.loginMode === 1 ? 2 : 1;
    },
    switchMode() {
      this.mode = this.mode === 1 ? 2 : 1;
    },
  },
};
</script>
