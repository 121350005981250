<template>
  <a-form-model
    :hideRequiredMark="true"
    :model="model"
    :rules="rules"
    :wrapper-col="{ span: 24 }"
    ref="upForm"
    @validate="validateOption"
    :validateOnRuleChange="true"
    class="login-container-form"
  >
    <a-form-model-item style="height: 40px; margin-bottom: 8px">
      <a-alert
        :message="validateAlert.msg"
        :type="validateAlert.type"
        show-icon
        v-if="validateAlert.hasError"
      />
    </a-form-model-item>
    <a-form-model-item prop="accountName">
      <a-input
        size="large"
        id="login-up-accountName"
        v-model="model.accountName"
        placeholder="请输入手机号/邮箱/账号ID"
      >
        <a-icon slot="prefix" type="user" />
        <template slot="suffix">
          <a-tooltip>
            <template slot="title">
              管理端账号与门店端账号互为独立账号，需分别注册，并使用各自账号密码登录。
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
        </template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input-password
        size="large"
        v-model="model.password"
        id="login-up-password"
        placeholder="请输入密码"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </a-form-model-item>
    <!-- <a-form-model-item>
      <a-checkbox v-model="model.rememberMe" style="float: right">
        3天内自动登录
      </a-checkbox>
    </a-form-model-item> -->
    <a-row>
      <a-form-model-item prop="agree" style="float: left">
        <a-checkbox v-model="model.agree"> 阅读并同意 </a-checkbox>
        <a-button type="link" style="padding: 0px" @click="goPrivacyPolicy">
          《用户隐私协议》
        </a-button>
      </a-form-model-item>
      <a-form-model-item style="float: right">
        <a-button type="link" @click="goRetrievePass"> 忘记密码 </a-button>
        <!-- <a-button type="link" @click="goRegister"> 免费注册 </a-button> -->
      </a-form-model-item>
    </a-row>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        name="login"
        :loading="formLoading"
        block
        @click="doSubmit"
      >
        登录
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { FormMixin } from "@/views/mixins/form";
export default {
  name: "login-up-form",
  props: {
    formLoading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      formName: "upForm",
      model: {
        accountName: "",
        password: "",
        rememberMe: false,
        agree: true,
      },
      rules: {
        accountName: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(
                  this.$alertableError({
                    msg: "手机号/邮箱/账号ID不能为空",
                    hide: event.target.name !== "login",
                    type: "error",
                  })
                );
              } else {
                callback();
              }
            },
          },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(
                  this.$alertableError({
                    msg: "密码不能为空",
                    hide: event.target.name !== "login",
                    type: "error",
                  })
                );
              } else {
                callback();
              }
            },
          },
        ],
        agree: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(
                  this.$alertableError({
                    msg: "请阅读并同意用户隐私协议",
                    type: "warning",
                  })
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
};
</script>