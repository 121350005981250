<template>
  <a-form-model
    :hideRequiredMark="true"
    :model="model"
    :rules="rules"
    :wrapper-col="{ span: 24 }"
    ref="emailForm"
    @validate="validateOption"
    :validateOnRuleChange="true"
    class="login-container-form"
  >
    <a-form-model-item style="height: 40px; margin-bottom: 8px">
      <a-alert
        :message="validateAlert.msg"
        :type="validateAlert.type"
        show-icon
        v-if="validateAlert.hasError"
      />
    </a-form-model-item>
    <a-form-model-item prop="accountName">
      <a-input
        size="large"
        id="login-email-accountName"
        v-model="model.accountName"
        placeholder="请输入电子邮箱"
      >
        <a-icon slot="prefix" type="mail" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="captcha">
      <a-input
        size="large"
        id="login-email-captcha"
        v-model="model.captcha"
        autocomplete="new-password"
        placeholder="请输入验证码"
      >
        <a-icon slot="prefix" type="search" />
        <template slot="suffix">
          <a-button
            type="link"
            v-if="!sendCaptchaStatus.s"
            @click="sendCaptcha"
            :disabled="!captchaBtnEnabled"
            >获取验证码</a-button
          >
          <a-button disabled type="link" v-if="sendCaptchaStatus.s"
            >重新获取({{ sendCaptchaStatus.time }}秒后)</a-button
          >
        </template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="agree">
      <a-checkbox v-model="model.agree"> 阅读并同意 </a-checkbox>
      <a-button type="link" style="padding: 0px" @click="goPrivacyPolicy">
        《用户隐私协议》
      </a-button>
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        name="login"
        block
        :loading="formLoading"
        @click="doSubmit"
      >
        登录
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { RegExpHelper } from "@/libs/regexp";
import { FormMixin } from "@/views/mixins/form";
export default {
  name: "login-email-captcha-form-model",
  mixins: [FormMixin],
  data() {
    return {
      formName: "emailForm",
      model: {
        accountName: "",
        captcha: "",
        rememberMe: false,
        agree: true,
      },
      rules: {
        accountName: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(
                  this.$alertableError({
                    msg: "电子邮箱不能为空",
                    hide: event.target.name !== "login",
                    type: "error",
                  })
                );
                this.captchaBtnEnabled = false;
              }
              if (!RegExpHelper.email.test(value)) {
                this.captchaBtnEnabled = false;
                callback(new Error("请输入正确的电子邮箱"));
              } else {
                this.captchaBtnEnabled = true;
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(
                  this.$alertableError({
                    msg: "验证码不能为空",
                    hide: event.target.name !== "login",
                    type: "error",
                  })
                );
              }
              if (!RegExpHelper.captcha.test(value)) {
                callback(new Error("验证码必须为6位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        agree: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(
                  this.$alertableError({
                    msg: "请阅读并同意用户隐私协议",
                    type: "warning",
                  })
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {},
};
</script>